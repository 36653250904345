export const NORMAL_VIEW = 1;
export const CR_VIEW = 2;
export const MOBILE_VIEW = 3;

const toUpperCase = (value) => {
  return value ? value.toUpperCase() : value;
};

export const getColumnDefs = (view, lineHeight) => {
  let columnDefs = [
    {
      headerName: "CR",
      field: "roomCode",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 3,
      showColumn: [NORMAL_VIEW],
    },
    {
      headerName: "CR",
      field: "roomValue",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 3,
      showColumn: [MOBILE_VIEW],
    },
    {
      headerName: "Show",
      field: "showName",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      suppressNavigable: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 5,
      showColumn: [NORMAL_VIEW],
    },
    {
      headerName: "Block",
      field: "blockName",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      cellStyle: { "text-align": "center" },
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 2,
      valueFormatter: (params) => toUpperCase(params.data.blockName),
      valueGetter: (params) => toUpperCase(params.data.blockName),
      showColumn: [NORMAL_VIEW],
    },
    {
      headerName: "Window",
      field: "window",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 4,
      valueFormatter: (params) => toUpperCase(params.data.window),
      valueGetter: (params) => params.data.windowStartTime || "",
      filterValueGetter: (params) => params.data.window,
      showColumn: [NORMAL_VIEW],
    },
    {
      headerName: "Hit Time",
      field: "hitTimevalue",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 3,
      valueFormatter: (params) => toUpperCase(params.data.hitTimevalue),
      valueGetter: (params) =>
        (params.data.hitDate || "") + params.data.hitTime,
      filterValueGetter: (params) => params.data.hitTimevalue,
      showColumn: [NORMAL_VIEW, CR_VIEW, MOBILE_VIEW],
    },
    {
      headerName: "Liveshot Name",
      field: "liveshotName",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "",
      flex: 6,
      showColumn: [NORMAL_VIEW, CR_VIEW, MOBILE_VIEW],
    },
    {
      headerName: "Location",
      field: "location",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      flex: 6,
      showColumn: [NORMAL_VIEW, CR_VIEW],
    },
    {
      headerName: "IFB",
      field: "comms",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "commsUpdatedTooltip",
      flex: 3,
      showColumn: [NORMAL_VIEW, CR_VIEW, MOBILE_VIEW],
    },
    {
      headerName: "Global Source",
      field: "global",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "inboundUpdatedTooltip",
      flex: 4,
      showColumn: [NORMAL_VIEW, CR_VIEW],
    },
    {
      headerName: "Source",
      field: "local",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "inboundUpdatedTooltip",
      flex: 3,
      showColumn: [NORMAL_VIEW],
    },
    {
      headerName: "RS",
      field: "local",
      resizable: true,
      sortable: true,
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "inboundUpdatedTooltip",
      flex: 3,
      showColumn: [CR_VIEW],
    },
    {
      headerName: "Ready",
      field: "ready",
      cellClass: "ready-cell",
      resizable: true,
      sortable: true,
      cellStyle: { border: "none !important", "text-align": "center" },
      suppressMenu: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      tooltipField: "liveshotUpdatedTooltip",
      flex: 2,
      showColumn: [NORMAL_VIEW, CR_VIEW, MOBILE_VIEW],
    },
  ];

  return columnDefs
    .filter((item) => item.showColumn.includes(view))
    .map((item) => {
      if (view == CR_VIEW) {
        item.wrapText = true;
        item.autoHeight = true;
        item.cellStyle = {
          lineHeight: `${lineHeight || 20}px`,
          fontWeight: "bold",
          ...item.cellStyle,
        };
        if (item.field == "hitTimevalue") {
          item.cellStyle.paddingLeft = "18px";
        }
      }
      return item;
    });
};

export const rowClassRules = {
  "text-gray": function (params) {
    return params.data.status === "CANCELED";
  },
  "text-blue": function (params) {
    return params.data.status === "READY_FOR_PCR";
  },
  "text-green": function (params) {
    return params.data.status === "READY_FOR_AIR";
  },
  "text-white": function (params) {
    return params.data.status === "READY_FOR_MTC";
  },
  "text-orange": function (params) {
    return params.data.status === "CLEAR";
  },
  "border-blue": function (params) {
    return params.data.liveshotBorder === true;
  },
};
